import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _fad542ec = () => interopDefault(import('../pages/accessibilite.vue' /* webpackChunkName: "pages/accessibilite" */))
const _41a922fb = () => interopDefault(import('../pages/bureau-etude-urbanisme.vue' /* webpackChunkName: "pages/bureau-etude-urbanisme" */))
const _4f4e72d6 = () => interopDefault(import('../pages/collectivites-territoriales.vue' /* webpackChunkName: "pages/collectivites-territoriales" */))
const _333f7562 = () => interopDefault(import('../pages/confidentialite.vue' /* webpackChunkName: "pages/confidentialite" */))
const _270e73f2 = () => interopDefault(import('../pages/ddt-ddtm-dreal.vue' /* webpackChunkName: "pages/ddt-ddtm-dreal" */))
const _5662a00f = () => interopDefault(import('../pages/exports.vue' /* webpackChunkName: "pages/exports" */))
const _70f74168 = () => interopDefault(import('../pages/guide.vue' /* webpackChunkName: "pages/guide" */))
const _483a0cfc = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _5f488f69 = () => interopDefault(import('../pages/loi-climat-et-resilience.vue' /* webpackChunkName: "pages/loi-climat-et-resilience" */))
const _85d434fa = () => interopDefault(import('../pages/mentions-legales.vue' /* webpackChunkName: "pages/mentions-legales" */))
const _466afd0e = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _66d3529c = () => interopDefault(import('../pages/stats/index.vue' /* webpackChunkName: "pages/stats/index" */))
const _37424c4e = () => interopDefault(import('../pages/validation.vue' /* webpackChunkName: "pages/validation" */))
const _986d5aac = () => interopDefault(import('../pages/ddt/enquete_overview.vue' /* webpackChunkName: "pages/ddt/enquete_overview" */))
const _d2a0a520 = () => interopDefault(import('../pages/dev/api.vue' /* webpackChunkName: "pages/dev/api" */))
const _ff380a1e = () => interopDefault(import('../pages/stats/state.vue' /* webpackChunkName: "pages/stats/state" */))
const _e898e228 = () => interopDefault(import('../pages/stats/urba.vue' /* webpackChunkName: "pages/stats/urba" */))
const _00e03afc = () => interopDefault(import('../pages/login/collectivites/explain.vue' /* webpackChunkName: "pages/login/collectivites/explain" */))
const _827caf32 = () => interopDefault(import('../pages/login/collectivites/signin.vue' /* webpackChunkName: "pages/login/collectivites/signin" */))
const _59500246 = () => interopDefault(import('../pages/login/collectivites/signup.vue' /* webpackChunkName: "pages/login/collectivites/signup" */))
const _c74628f4 = () => interopDefault(import('../pages/login/ddt/explain.vue' /* webpackChunkName: "pages/login/ddt/explain" */))
const _376d6ee3 = () => interopDefault(import('../pages/login/ddt/signin.vue' /* webpackChunkName: "pages/login/ddt/signin" */))
const _4c03c559 = () => interopDefault(import('../pages/login/ddt/signup.vue' /* webpackChunkName: "pages/login/ddt/signup" */))
const _13267222 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _3fd52eae = () => interopDefault(import('../pages/collectivites/_collectiviteId.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId" */))
const _99f90a9e = () => interopDefault(import('../pages/collectivites/_collectiviteId/index.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/index" */))
const _241b54f2 = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees" */))
const _2f11f8e2 = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees/data.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees/data" */))
const _3b616eb6 = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees/geobretagne.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees/geobretagne" */))
const _4971091e = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees/geoide.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees/geoide" */))
const _adce3f96 = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees/georisques.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees/georisques" */))
const _bdfc44b8 = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees/gpu.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees/gpu" */))
const _3fe6aaca = () => interopDefault(import('../pages/collectivites/_collectiviteId/donnees/inpn.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/donnees/inpn" */))
const _27b02c44 = () => interopDefault(import('../pages/collectivites/_collectiviteId/glossaire.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/glossaire" */))
const _3110ef9e = () => interopDefault(import('../pages/collectivites/_collectiviteId/pac.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/pac" */))
const _4048ab7b = () => interopDefault(import('../pages/collectivites/_collectiviteId/prescriptions/index.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/prescriptions/index" */))
const _715a11c9 = () => interopDefault(import('../pages/collectivites/_collectiviteId/ressources.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/ressources" */))
const _ee94e7ec = () => interopDefault(import('../pages/collectivites/_collectiviteId/prescriptions/add.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/prescriptions/add" */))
const _ff191e82 = () => interopDefault(import('../pages/collectivites/_collectiviteId/prescriptions/signup.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/prescriptions/signup" */))
const _94c9e17a = () => interopDefault(import('../pages/collectivites/_collectiviteId/procedures/add.vue' /* webpackChunkName: "pages/collectivites/_collectiviteId/procedures/add" */))
const _1e6a72aa = () => interopDefault(import('../pages/ddt/_departement.vue' /* webpackChunkName: "pages/ddt/_departement" */))
const _2cb460ee = () => interopDefault(import('../pages/ddt/_departement/index.vue' /* webpackChunkName: "pages/ddt/_departement/index" */))
const _94fb33e2 = () => interopDefault(import('../pages/ddt/_departement/collectivites/index.vue' /* webpackChunkName: "pages/ddt/_departement/collectivites/index" */))
const _73b6d9ae = () => interopDefault(import('../pages/ddt/_departement/pac.vue' /* webpackChunkName: "pages/ddt/_departement/pac" */))
const _5bdbf3d2 = () => interopDefault(import('../pages/ddt/_departement/procedures/index.vue' /* webpackChunkName: "pages/ddt/_departement/procedures/index" */))
const _1bbdb03c = () => interopDefault(import('../pages/ddt/_departement/procedures/choix-collectivite.vue' /* webpackChunkName: "pages/ddt/_departement/procedures/choix-collectivite" */))
const _3821ce4b = () => interopDefault(import('../pages/ddt/_departement/collectivites/_collectiviteId/commune.vue' /* webpackChunkName: "pages/ddt/_departement/collectivites/_collectiviteId/commune" */))
const _67bb5814 = () => interopDefault(import('../pages/ddt/_departement/collectivites/_collectiviteId/epci.vue' /* webpackChunkName: "pages/ddt/_departement/collectivites/_collectiviteId/epci" */))
const _53d072e0 = () => interopDefault(import('../pages/ddt/_departement/collectivites/_collectiviteId/procedure/add.vue' /* webpackChunkName: "pages/ddt/_departement/collectivites/_collectiviteId/procedure/add" */))
const _174805dc = () => interopDefault(import('../pages/frise/_procedureId/index.vue' /* webpackChunkName: "pages/frise/_procedureId/index" */))
const _127ef997 = () => interopDefault(import('../pages/print/_projectId.vue' /* webpackChunkName: "pages/print/_projectId" */))
const _54f030d0 = () => interopDefault(import('../pages/trames/_githubRef.vue' /* webpackChunkName: "pages/trames/_githubRef" */))
const _23c0904b = () => interopDefault(import('../pages/documents/_githubRef/pac.vue' /* webpackChunkName: "pages/documents/_githubRef/pac" */))
const _c063513e = () => interopDefault(import('../pages/frise/_procedureId/add.vue' /* webpackChunkName: "pages/frise/_procedureId/add" */))
const _96b6a10e = () => interopDefault(import('../pages/frise/_procedureId/invite.vue' /* webpackChunkName: "pages/frise/_procedureId/invite" */))
const _1b55e006 = () => interopDefault(import('../pages/frise/_procedureId/_eventId.vue' /* webpackChunkName: "pages/frise/_procedureId/_eventId" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/accessibilite",
    component: _fad542ec,
    name: "accessibilite"
  }, {
    path: "/bureau-etude-urbanisme",
    component: _41a922fb,
    name: "bureau-etude-urbanisme"
  }, {
    path: "/collectivites-territoriales",
    component: _4f4e72d6,
    name: "collectivites-territoriales"
  }, {
    path: "/confidentialite",
    component: _333f7562,
    name: "confidentialite"
  }, {
    path: "/ddt-ddtm-dreal",
    component: _270e73f2,
    name: "ddt-ddtm-dreal"
  }, {
    path: "/exports",
    component: _5662a00f,
    name: "exports"
  }, {
    path: "/guide",
    component: _70f74168,
    name: "guide"
  }, {
    path: "/login",
    component: _483a0cfc,
    name: "login"
  }, {
    path: "/loi-climat-et-resilience",
    component: _5f488f69,
    name: "loi-climat-et-resilience"
  }, {
    path: "/mentions-legales",
    component: _85d434fa,
    name: "mentions-legales"
  }, {
    path: "/profile",
    component: _466afd0e,
    name: "profile"
  }, {
    path: "/stats",
    component: _66d3529c,
    name: "stats"
  }, {
    path: "/validation",
    component: _37424c4e,
    name: "validation"
  }, {
    path: "/ddt/enquete_overview",
    component: _986d5aac,
    name: "ddt-enquete_overview"
  }, {
    path: "/dev/api",
    component: _d2a0a520,
    name: "dev-api"
  }, {
    path: "/stats/state",
    component: _ff380a1e,
    name: "stats-state"
  }, {
    path: "/stats/urba",
    component: _e898e228,
    name: "stats-urba"
  }, {
    path: "/login/collectivites/explain",
    component: _00e03afc,
    name: "login-collectivites-explain"
  }, {
    path: "/login/collectivites/signin",
    component: _827caf32,
    name: "login-collectivites-signin"
  }, {
    path: "/login/collectivites/signup",
    component: _59500246,
    name: "login-collectivites-signup"
  }, {
    path: "/login/ddt/explain",
    component: _c74628f4,
    name: "login-ddt-explain"
  }, {
    path: "/login/ddt/signin",
    component: _376d6ee3,
    name: "login-ddt-signin"
  }, {
    path: "/login/ddt/signup",
    component: _4c03c559,
    name: "login-ddt-signup"
  }, {
    path: "/",
    component: _13267222,
    name: "index"
  }, {
    path: "/collectivites/:collectiviteId?",
    component: _3fd52eae,
    children: [{
      path: "",
      component: _99f90a9e,
      name: "collectivites-collectiviteId"
    }, {
      path: "donnees",
      component: _241b54f2,
      name: "collectivites-collectiviteId-donnees",
      children: [{
        path: "data",
        component: _2f11f8e2,
        name: "collectivites-collectiviteId-donnees-data"
      }, {
        path: "geobretagne",
        component: _3b616eb6,
        name: "collectivites-collectiviteId-donnees-geobretagne"
      }, {
        path: "geoide",
        component: _4971091e,
        name: "collectivites-collectiviteId-donnees-geoide"
      }, {
        path: "georisques",
        component: _adce3f96,
        name: "collectivites-collectiviteId-donnees-georisques"
      }, {
        path: "gpu",
        component: _bdfc44b8,
        name: "collectivites-collectiviteId-donnees-gpu"
      }, {
        path: "inpn",
        component: _3fe6aaca,
        name: "collectivites-collectiviteId-donnees-inpn"
      }]
    }, {
      path: "glossaire",
      component: _27b02c44,
      name: "collectivites-collectiviteId-glossaire"
    }, {
      path: "pac",
      component: _3110ef9e,
      name: "collectivites-collectiviteId-pac"
    }, {
      path: "prescriptions",
      component: _4048ab7b,
      name: "collectivites-collectiviteId-prescriptions"
    }, {
      path: "ressources",
      component: _715a11c9,
      name: "collectivites-collectiviteId-ressources"
    }, {
      path: "prescriptions/add",
      component: _ee94e7ec,
      name: "collectivites-collectiviteId-prescriptions-add"
    }, {
      path: "prescriptions/signup",
      component: _ff191e82,
      name: "collectivites-collectiviteId-prescriptions-signup"
    }, {
      path: "procedures/add",
      component: _94c9e17a,
      name: "collectivites-collectiviteId-procedures-add"
    }]
  }, {
    path: "/ddt/:departement?",
    component: _1e6a72aa,
    children: [{
      path: "",
      component: _2cb460ee,
      name: "ddt-departement"
    }, {
      path: "collectivites",
      component: _94fb33e2,
      name: "ddt-departement-collectivites"
    }, {
      path: "pac",
      component: _73b6d9ae,
      name: "ddt-departement-pac"
    }, {
      path: "procedures",
      component: _5bdbf3d2,
      name: "ddt-departement-procedures"
    }, {
      path: "procedures/choix-collectivite",
      component: _1bbdb03c,
      name: "ddt-departement-procedures-choix-collectivite"
    }, {
      path: "collectivites/:collectiviteId/commune",
      component: _3821ce4b,
      name: "ddt-departement-collectivites-collectiviteId-commune"
    }, {
      path: "collectivites/:collectiviteId/epci",
      component: _67bb5814,
      name: "ddt-departement-collectivites-collectiviteId-epci"
    }, {
      path: "collectivites/:collectiviteId/procedure/add",
      component: _53d072e0,
      name: "ddt-departement-collectivites-collectiviteId-procedure-add"
    }]
  }, {
    path: "/frise/:procedureId",
    component: _174805dc,
    name: "frise-procedureId"
  }, {
    path: "/print/:projectId?",
    component: _127ef997,
    name: "print-projectId"
  }, {
    path: "/trames/:githubRef?",
    component: _54f030d0,
    name: "trames-githubRef"
  }, {
    path: "/documents/:githubRef?/pac",
    component: _23c0904b,
    name: "documents-githubRef-pac"
  }, {
    path: "/frise/:procedureId?/add",
    component: _c063513e,
    name: "frise-procedureId-add"
  }, {
    path: "/frise/:procedureId?/invite",
    component: _96b6a10e,
    name: "frise-procedureId-invite"
  }, {
    path: "/frise/:procedureId?/:eventId",
    component: _1b55e006,
    name: "frise-procedureId-eventId"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
