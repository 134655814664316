export const DataSourceCard = () => import('../../components/DataSourceCard.vue' /* webpackChunkName: "components/data-source-card" */).then(c => wrapFunctional(c.default || c))
export const DataSourcesList = () => import('../../components/DataSourcesList.vue' /* webpackChunkName: "components/data-sources-list" */).then(c => wrapFunctional(c.default || c))
export const GeoBretagneViewer = () => import('../../components/GeoBretagneViewer.vue' /* webpackChunkName: "components/geo-bretagne-viewer" */).then(c => wrapFunctional(c.default || c))
export const GeoIDECardList = () => import('../../components/GeoIDECardList.vue' /* webpackChunkName: "components/geo-i-d-e-card-list" */).then(c => wrapFunctional(c.default || c))
export const RecordCard = () => import('../../components/RecordCard.vue' /* webpackChunkName: "components/record-card" */).then(c => wrapFunctional(c.default || c))
export const RecordList = () => import('../../components/RecordList.vue' /* webpackChunkName: "components/record-list" */).then(c => wrapFunctional(c.default || c))
export const SignalementProbleme = () => import('../../components/SignalementProbleme.vue' /* webpackChunkName: "components/signalement-probleme" */).then(c => wrapFunctional(c.default || c))
export const VBigRadio = () => import('../../components/VBigRadio.vue' /* webpackChunkName: "components/v-big-radio" */).then(c => wrapFunctional(c.default || c))
export const VCollectivitesAutocomplete = () => import('../../components/VCollectivitesAutocomplete.vue' /* webpackChunkName: "components/v-collectivites-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const VConsentSnackbar = () => import('../../components/VConsentSnackbar.vue' /* webpackChunkName: "components/v-consent-snackbar" */).then(c => wrapFunctional(c.default || c))
export const VDeptAutocomplete = () => import('../../components/VDeptAutocomplete.vue' /* webpackChunkName: "components/v-dept-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const VDocumentSelect = () => import('../../components/VDocumentSelect.vue' /* webpackChunkName: "components/v-document-select" */).then(c => wrapFunctional(c.default || c))
export const VEpciAutocomplete = () => import('../../components/VEpciAutocomplete.vue' /* webpackChunkName: "components/v-epci-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const VFileDropzone = () => import('../../components/VFileDropzone.vue' /* webpackChunkName: "components/v-file-dropzone" */).then(c => wrapFunctional(c.default || c))
export const VFloatingShape = () => import('../../components/VFloatingShape.vue' /* webpackChunkName: "components/v-floating-shape" */).then(c => wrapFunctional(c.default || c))
export const VGlobalLoader = () => import('../../components/VGlobalLoader.vue' /* webpackChunkName: "components/v-global-loader" */).then(c => wrapFunctional(c.default || c))
export const VRegionAutocomplete = () => import('../../components/VRegionAutocomplete.vue' /* webpackChunkName: "components/v-region-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const VTextDatePicker = () => import('../../components/VTextDatePicker.vue' /* webpackChunkName: "components/v-text-date-picker" */).then(c => wrapFunctional(c.default || c))
export const VTiptap = () => import('../../components/VTiptap.vue' /* webpackChunkName: "components/v-tiptap" */).then(c => wrapFunctional(c.default || c))
export const VTiptapItems = () => import('../../components/VTiptapItems.vue' /* webpackChunkName: "components/v-tiptap-items" */).then(c => wrapFunctional(c.default || c))
export const VTiptapTableItems = () => import('../../components/VTiptapTableItems.vue' /* webpackChunkName: "components/v-tiptap-table-items" */).then(c => wrapFunctional(c.default || c))
export const VTownAutocomplete = () => import('../../components/VTownAutocomplete.vue' /* webpackChunkName: "components/v-town-autocomplete" */).then(c => wrapFunctional(c.default || c))
export const AdminDdtRequestDialog = () => import('../../components/Admin/DdtRequestDialog.vue' /* webpackChunkName: "components/admin-ddt-request-dialog" */).then(c => wrapFunctional(c.default || c))
export const AuthResetPasswordDialog = () => import('../../components/Auth/ResetPasswordDialog.vue' /* webpackChunkName: "components/auth-reset-password-dialog" */).then(c => wrapFunctional(c.default || c))
export const DataGeorisquesTable = () => import('../../components/Data/GeorisquesTable.vue' /* webpackChunkName: "components/data-georisques-table" */).then(c => wrapFunctional(c.default || c))
export const DataGpuDocumentCard = () => import('../../components/Data/GpuDocumentCard.vue' /* webpackChunkName: "components/data-gpu-document-card" */).then(c => wrapFunctional(c.default || c))
export const DataINPNTable = () => import('../../components/Data/INPNTable.vue' /* webpackChunkName: "components/data-i-n-p-n-table" */).then(c => wrapFunctional(c.default || c))
export const ChartsAxis = () => import('../../components/Charts/Axis.vue' /* webpackChunkName: "components/charts-axis" */).then(c => wrapFunctional(c.default || c))
export const ChartsBars = () => import('../../components/Charts/Bars.vue' /* webpackChunkName: "components/charts-bars" */).then(c => wrapFunctional(c.default || c))
export const ChartsCircles = () => import('../../components/Charts/Circles.vue' /* webpackChunkName: "components/charts-circles" */).then(c => wrapFunctional(c.default || c))
export const ChartsLabels = () => import('../../components/Charts/Labels.vue' /* webpackChunkName: "components/charts-labels" */).then(c => wrapFunctional(c.default || c))
export const ChartsLayer = () => import('../../components/Charts/Layer.js' /* webpackChunkName: "components/charts-layer" */).then(c => wrapFunctional(c.default || c))
export const ChartsLayout = () => import('../../components/Charts/Layout.vue' /* webpackChunkName: "components/charts-layout" */).then(c => wrapFunctional(c.default || c))
export const ChartsLine = () => import('../../components/Charts/Line.vue' /* webpackChunkName: "components/charts-line" */).then(c => wrapFunctional(c.default || c))
export const ChartsMap = () => import('../../components/Charts/Map.vue' /* webpackChunkName: "components/charts-map" */).then(c => wrapFunctional(c.default || c))
export const ChartsScales = () => import('../../components/Charts/Scales.vue' /* webpackChunkName: "components/charts-scales" */).then(c => wrapFunctional(c.default || c))
export const DashboardDUInsertDialog = () => import('../../components/Dashboard/DUInsertDialog.vue' /* webpackChunkName: "components/dashboard-d-u-insert-dialog" */).then(c => wrapFunctional(c.default || c))
export const DashboardDUItem = () => import('../../components/Dashboard/DUItem.vue' /* webpackChunkName: "components/dashboard-d-u-item" */).then(c => wrapFunctional(c.default || c))
export const DashboardDUItemsList = () => import('../../components/Dashboard/DUItemsList.vue' /* webpackChunkName: "components/dashboard-d-u-items-list" */).then(c => wrapFunctional(c.default || c))
export const DashboardDUModalPerimetre = () => import('../../components/Dashboard/DUModalPerimetre.vue' /* webpackChunkName: "components/dashboard-d-u-modal-perimetre" */).then(c => wrapFunctional(c.default || c))
export const DashboardDUProcedureItem = () => import('../../components/Dashboard/DUProcedureItem.vue' /* webpackChunkName: "components/dashboard-d-u-procedure-item" */).then(c => wrapFunctional(c.default || c))
export const DashboardDUSubProcedureItem = () => import('../../components/Dashboard/DUSubProcedureItem.vue' /* webpackChunkName: "components/dashboard-d-u-sub-procedure-item" */).then(c => wrapFunctional(c.default || c))
export const DashboardPerimetreDialog = () => import('../../components/Dashboard/PerimetreDialog.vue' /* webpackChunkName: "components/dashboard-perimetre-dialog" */).then(c => wrapFunctional(c.default || c))
export const DashboardSCOTsDepList = () => import('../../components/Dashboard/SCOTsDepList.vue' /* webpackChunkName: "components/dashboard-s-c-o-ts-dep-list" */).then(c => wrapFunctional(c.default || c))
export const DashboardSharePACDialog = () => import('../../components/Dashboard/SharePACDialog.vue' /* webpackChunkName: "components/dashboard-share-p-a-c-dialog" */).then(c => wrapFunctional(c.default || c))
export const DdtPerimeterCheckInput = () => import('../../components/Ddt/PerimeterCheckInput.vue' /* webpackChunkName: "components/ddt-perimeter-check-input" */).then(c => wrapFunctional(c.default || c))
export const FriseCheckpointsCard = () => import('../../components/Frise/CheckpointsCard.vue' /* webpackChunkName: "components/frise-checkpoints-card" */).then(c => wrapFunctional(c.default || c))
export const FriseDgdDialog = () => import('../../components/Frise/DgdDialog.vue' /* webpackChunkName: "components/frise-dgd-dialog" */).then(c => wrapFunctional(c.default || c))
export const FriseDgdItem = () => import('../../components/Frise/DgdItem.vue' /* webpackChunkName: "components/frise-dgd-item" */).then(c => wrapFunctional(c.default || c))
export const FriseDocEventCard = () => import('../../components/Frise/DocEventCard.vue' /* webpackChunkName: "components/frise-doc-event-card" */).then(c => wrapFunctional(c.default || c))
export const FriseDocTimeline = () => import('../../components/Frise/DocTimeline.vue' /* webpackChunkName: "components/frise-doc-timeline" */).then(c => wrapFunctional(c.default || c))
export const FriseEventAttachementsCard = () => import('../../components/Frise/EventAttachementsCard.vue' /* webpackChunkName: "components/frise-event-attachements-card" */).then(c => wrapFunctional(c.default || c))
export const FriseEventCard = () => import('../../components/Frise/EventCard.vue' /* webpackChunkName: "components/frise-event-card" */).then(c => wrapFunctional(c.default || c))
export const FriseEventForm = () => import('../../components/Frise/EventForm.vue' /* webpackChunkName: "components/frise-event-form" */).then(c => wrapFunctional(c.default || c))
export const FriseEventSelector = () => import('../../components/Frise/EventSelector.vue' /* webpackChunkName: "components/frise-event-selector" */).then(c => wrapFunctional(c.default || c))
export const FriseInfosDialog = () => import('../../components/Frise/InfosDialog.vue' /* webpackChunkName: "components/frise-infos-dialog" */).then(c => wrapFunctional(c.default || c))
export const FriseLastUpdatesCard = () => import('../../components/Frise/LastUpdatesCard.vue' /* webpackChunkName: "components/frise-last-updates-card" */).then(c => wrapFunctional(c.default || c))
export const FriseRecommendedEventCard = () => import('../../components/Frise/RecommendedEventCard.vue' /* webpackChunkName: "components/frise-recommended-event-card" */).then(c => wrapFunctional(c.default || c))
export const FriseShareDialog = () => import('../../components/Frise/ShareDialog.vue' /* webpackChunkName: "components/frise-share-dialog" */).then(c => wrapFunctional(c.default || c))
export const FriseVoletQualiDialog = () => import('../../components/Frise/VoletQualiDialog.vue' /* webpackChunkName: "components/frise-volet-quali-dialog" */).then(c => wrapFunctional(c.default || c))
export const GlossaireWordsList = () => import('../../components/Glossaire/WordsList.vue' /* webpackChunkName: "components/glossaire-words-list" */).then(c => wrapFunctional(c.default || c))
export const InputsEditableText = () => import('../../components/Inputs/EditableText.vue' /* webpackChunkName: "components/inputs-editable-text" */).then(c => wrapFunctional(c.default || c))
export const InputsPasswordTextField = () => import('../../components/Inputs/PasswordTextField.vue' /* webpackChunkName: "components/inputs-password-text-field" */).then(c => wrapFunctional(c.default || c))
export const LandingCard = () => import('../../components/Landing/Card.vue' /* webpackChunkName: "components/landing-card" */).then(c => wrapFunctional(c.default || c))
export const LandingNewsLetterForm = () => import('../../components/Landing/NewsLetterForm.vue' /* webpackChunkName: "components/landing-news-letter-form" */).then(c => wrapFunctional(c.default || c))
export const LandingSearchForm = () => import('../../components/Landing/SearchForm.vue' /* webpackChunkName: "components/landing-search-form" */).then(c => wrapFunctional(c.default || c))
export const LandingSlidingTabs = () => import('../../components/Landing/SlidingTabs.vue' /* webpackChunkName: "components/landing-sliding-tabs" */).then(c => wrapFunctional(c.default || c))
export const LandingWordSlider = () => import('../../components/Landing/WordSlider.vue' /* webpackChunkName: "components/landing-word-slider" */).then(c => wrapFunctional(c.default || c))
export const LayoutsAppBar = () => import('../../components/Layouts/AppBar.vue' /* webpackChunkName: "components/layouts-app-bar" */).then(c => wrapFunctional(c.default || c))
export const LayoutsBannerAlert = () => import('../../components/Layouts/BannerAlert.vue' /* webpackChunkName: "components/layouts-banner-alert" */).then(c => wrapFunctional(c.default || c))
export const LayoutsCustomApp = () => import('../../components/Layouts/CustomApp.vue' /* webpackChunkName: "components/layouts-custom-app" */).then(c => wrapFunctional(c.default || c))
export const LayoutsFooter = () => import('../../components/Layouts/Footer.vue' /* webpackChunkName: "components/layouts-footer" */).then(c => wrapFunctional(c.default || c))
export const OnboardingSignupCollectiviteForm = () => import('../../components/Onboarding/SignupCollectiviteForm.vue' /* webpackChunkName: "components/onboarding-signup-collectivite-form" */).then(c => wrapFunctional(c.default || c))
export const OnboardingSignupForm = () => import('../../components/Onboarding/SignupForm.vue' /* webpackChunkName: "components/onboarding-signup-form" */).then(c => wrapFunctional(c.default || c))
export const PACSectionCard = () => import('../../components/PAC/SectionCard.vue' /* webpackChunkName: "components/p-a-c-section-card" */).then(c => wrapFunctional(c.default || c))
export const PACTrameGhostSectionsDialog = () => import('../../components/PAC/TrameGhostSectionsDialog.vue' /* webpackChunkName: "components/p-a-c-trame-ghost-sections-dialog" */).then(c => wrapFunctional(c.default || c))
export const PrescriptionItemListRead = () => import('../../components/Prescription/ItemListRead.vue' /* webpackChunkName: "components/prescription-item-list-read" */).then(c => wrapFunctional(c.default || c))
export const PrescriptionYouWantCard = () => import('../../components/Prescription/YouWantCard.vue' /* webpackChunkName: "components/prescription-you-want-card" */).then(c => wrapFunctional(c.default || c))
export const ProceduresInsertForm = () => import('../../components/Procedures/InsertForm.vue' /* webpackChunkName: "components/procedures-insert-form" */).then(c => wrapFunctional(c.default || c))
export const ProceduresInsertTabs = () => import('../../components/Procedures/InsertTabs.vue' /* webpackChunkName: "components/procedures-insert-tabs" */).then(c => wrapFunctional(c.default || c))
export const RessourcesList = () => import('../../components/Ressources/RessourcesList.vue' /* webpackChunkName: "components/ressources-list" */).then(c => wrapFunctional(c.default || c))
export const StatsBarsCard = () => import('../../components/Stats/BarsCard.vue' /* webpackChunkName: "components/stats-bars-card" */).then(c => wrapFunctional(c.default || c))
export const StatsBignumberCard = () => import('../../components/Stats/BignumberCard.vue' /* webpackChunkName: "components/stats-bignumber-card" */).then(c => wrapFunctional(c.default || c))
export const StatsDdtDashboard = () => import('../../components/Stats/DdtDashboard.vue' /* webpackChunkName: "components/stats-ddt-dashboard" */).then(c => wrapFunctional(c.default || c))
export const StatsDeptsMapCard = () => import('../../components/Stats/DeptsMapCard.vue' /* webpackChunkName: "components/stats-depts-map-card" */).then(c => wrapFunctional(c.default || c))
export const StatsDonutCard = () => import('../../components/Stats/DonutCard.vue' /* webpackChunkName: "components/stats-donut-card" */).then(c => wrapFunctional(c.default || c))
export const StatsFdrDashboard = () => import('../../components/Stats/FdrDashboard.vue' /* webpackChunkName: "components/stats-fdr-dashboard" */).then(c => wrapFunctional(c.default || c))
export const StatsLineCard = () => import('../../components/Stats/LineCard.vue' /* webpackChunkName: "components/stats-line-card" */).then(c => wrapFunctional(c.default || c))
export const StatsNpsDashboard = () => import('../../components/Stats/NpsDashboard.vue' /* webpackChunkName: "components/stats-nps-dashboard" */).then(c => wrapFunctional(c.default || c))
export const StatsPacDashboard = () => import('../../components/Stats/PacDashboard.vue' /* webpackChunkName: "components/stats-pac-dashboard" */).then(c => wrapFunctional(c.default || c))
export const StatsProceduresDashboard = () => import('../../components/Stats/ProceduresDashboard.vue' /* webpackChunkName: "components/stats-procedures-dashboard" */).then(c => wrapFunctional(c.default || c))
export const StatsSocleDashboard = () => import('../../components/Stats/SocleDashboard.vue' /* webpackChunkName: "components/stats-socle-dashboard" */).then(c => wrapFunctional(c.default || c))
export const StatsSparklineCard = () => import('../../components/Stats/SparklineCard.vue' /* webpackChunkName: "components/stats-sparkline-card" */).then(c => wrapFunctional(c.default || c))
export const StatsTextCard = () => import('../../components/Stats/TextCard.vue' /* webpackChunkName: "components/stats-text-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardCollectivitesInnerNav = () => import('../../components/Dashboard/collectivites/InnerNav.vue' /* webpackChunkName: "components/dashboard-collectivites-inner-nav" */).then(c => wrapFunctional(c.default || c))
export const FriseDgdStepForm = () => import('../../components/Frise/Dgd/StepForm.vue' /* webpackChunkName: "components/frise-dgd-step-form" */).then(c => wrapFunctional(c.default || c))
export const FriseDgdVersExpPanel = () => import('../../components/Frise/Dgd/VersExpPanel.vue' /* webpackChunkName: "components/frise-dgd-vers-exp-panel" */).then(c => wrapFunctional(c.default || c))
export const FriseDgdVersStepPanel = () => import('../../components/Frise/Dgd/VersStepPanel.vue' /* webpackChunkName: "components/frise-dgd-vers-step-panel" */).then(c => wrapFunctional(c.default || c))
export const FriseDgdVersementForm = () => import('../../components/Frise/Dgd/VersementForm.vue' /* webpackChunkName: "components/frise-dgd-versement-form" */).then(c => wrapFunctional(c.default || c))
export const PACEditingAdvicesCard = () => import('../../components/PAC/Editing/AdvicesCard.vue' /* webpackChunkName: "components/p-a-c-editing-advices-card" */).then(c => wrapFunctional(c.default || c))
export const PACEditingCancelDialog = () => import('../../components/PAC/Editing/CancelDialog.vue' /* webpackChunkName: "components/p-a-c-editing-cancel-dialog" */).then(c => wrapFunctional(c.default || c))
export const PACEditingDiffCard = () => import('../../components/PAC/Editing/DiffCard.vue' /* webpackChunkName: "components/p-a-c-editing-diff-card" */).then(c => wrapFunctional(c.default || c))
export const PACEditingGitAddSectionDialog = () => import('../../components/PAC/Editing/GitAddSectionDialog.vue' /* webpackChunkName: "components/p-a-c-editing-git-add-section-dialog" */).then(c => wrapFunctional(c.default || c))
export const PACEditingGitRemoveSectionDialog = () => import('../../components/PAC/Editing/GitRemoveSectionDialog.vue' /* webpackChunkName: "components/p-a-c-editing-git-remove-section-dialog" */).then(c => wrapFunctional(c.default || c))
export const PACEditingParentDiffDialog = () => import('../../components/PAC/Editing/ParentDiffDialog.vue' /* webpackChunkName: "components/p-a-c-editing-parent-diff-dialog" */).then(c => wrapFunctional(c.default || c))
export const PACEditingReadOnlyCard = () => import('../../components/PAC/Editing/ReadOnlyCard.vue' /* webpackChunkName: "components/p-a-c-editing-read-only-card" */).then(c => wrapFunctional(c.default || c))
export const PACPDFGardeTemplate = () => import('../../components/PAC/PDF/GardeTemplate.vue' /* webpackChunkName: "components/p-a-c-p-d-f-garde-template" */).then(c => wrapFunctional(c.default || c))
export const PACPDFPagesCounters = () => import('../../components/PAC/PDF/PagesCounters.vue' /* webpackChunkName: "components/p-a-c-p-d-f-pages-counters" */).then(c => wrapFunctional(c.default || c))
export const PACPDFPagesTemplate = () => import('../../components/PAC/PDF/PagesTemplate.vue' /* webpackChunkName: "components/p-a-c-p-d-f-pages-template" */).then(c => wrapFunctional(c.default || c))
export const PACPDFSectionTemplate = () => import('../../components/PAC/PDF/SectionTemplate.vue' /* webpackChunkName: "components/p-a-c-p-d-f-section-template" */).then(c => wrapFunctional(c.default || c))
export const PACPDFTableOfContent = () => import('../../components/PAC/PDF/TableOfContent.vue' /* webpackChunkName: "components/p-a-c-p-d-f-table-of-content" */).then(c => wrapFunctional(c.default || c))
export const PACSectionsDataAttachmentsCards = () => import('../../components/PAC/Sections/DataAttachmentsCards.vue' /* webpackChunkName: "components/p-a-c-sections-data-attachments-cards" */).then(c => wrapFunctional(c.default || c))
export const PACSectionsDataAttachmentsDialog = () => import('../../components/PAC/Sections/DataAttachmentsDialog.vue' /* webpackChunkName: "components/p-a-c-sections-data-attachments-dialog" */).then(c => wrapFunctional(c.default || c))
export const PACSectionsFileAttachmentsChips = () => import('../../components/PAC/Sections/FileAttachmentsChips.vue' /* webpackChunkName: "components/p-a-c-sections-file-attachments-chips" */).then(c => wrapFunctional(c.default || c))
export const PACSectionsFileAttachmentsDialog = () => import('../../components/PAC/Sections/FileAttachmentsDialog.vue' /* webpackChunkName: "components/p-a-c-sections-file-attachments-dialog" */).then(c => wrapFunctional(c.default || c))
export const StatsStateCommunesStateMap = () => import('../../components/Stats/State/CommunesStateMap.vue' /* webpackChunkName: "components/stats-state-communes-state-map" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
